function addApplication(editorSDK, appToken, widgetDef) {
    return editorSDK.tpa.add.application(appToken, widgetDef)
}

function addComponent(editorSDK, appToken, options) {
    return editorSDK.tpa.add.component(appToken, options)
}

function isApplicationInstalled({editorSDK, appToken, appDefinitionId}) {
    return editorSDK.tpa.isApplicationInstalled(appToken, {appDefinitionId})
}

function isAppSectionInstalled({editorSDK, appToken, appDefinitionId, sectionId}) {
    return editorSDK.tpa.isAppSectionInstalled(appToken, {appDefinitionId, sectionId})
}

function getDataByAppDefId({editorSDK, appToken, appDefinitionId}) {
    return editorSDK.tpa.app.getDataByAppDefId(appToken, appDefinitionId)
}

function getAllCompsByApplicationId({editorSDK, appToken, applicationId}) {
    return editorSDK.tpa.app.getAllCompsByApplicationId(appToken, applicationId)
}

async function getAppDefIdbyTpa({editorSDK, tpaAppId}) {
    const components = await editorSDK.tpa.app.getAllCompsByApplicationId('', tpaAppId)
    return components && components.length > 0 ? components[0].appDefinitionId : undefined
}

export {
    addApplication,
    addComponent,
    isApplicationInstalled,
    isAppSectionInstalled,
    getDataByAppDefId,
    getAllCompsByApplicationId,
    getAppDefIdbyTpa
}
